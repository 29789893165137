const Modal = ({
	title = null,
	children = null,
	className = "",
	onClose = () => null,
}) => {
	return (
		<div className={`modal-container ${className}`}>
			<div
				className="curtain"
				onClick={() => onClose()}></div>

			<div className="modal">
				<div className="modal-header">
					<h4 className="m-b-xs">{title}</h4>
					<button
						className="close-button"
						onClick={() => onClose()}>
						<img
							src="/images/icons/close.svg"
							alt=""
						/>
					</button>
				</div>

				{children}
			</div>
		</div>
	);
};

export default Modal;
