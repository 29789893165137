export const CallPlatforms = {
	Zoom: "zoom",
	GoogleMeet: "google-meet",
	MicrosoftTeams: "microsoft-teams",
};

export const CallRoles = {
	Caller: "caller",
	Consultant: "consultant",
};
