import { toast } from "react-toastify";

const colors = {
	info: "#10abe2",
	success: "#00A699",
	warn: "#eabb53",
	error: "#db4437",
};

export default {
	info: (message) => {
		toast.info(message, { backgroundColor: colors.info });
	},
	success: (message) => {
		toast.success(message, { backgroundColor: colors.success });
	},
	warn: (message) => {
		toast.warn(message, { backgroundColor: colors.warn });
	},
	error: (message) => {
		toast.error(message, { backgroundColor: colors.error });
	},
};

export const showToastIfFails =
	(callback) =>
	async (...args) => {
		try {
			await callback(...args);
		} catch (error) {
			console.error(error);
			toast.error(
				error?.response?.data?.message || error.message,
				"error"
			);
		}
	};

export const info = (message) => {
	toast.info(message, { backgroundColor: colors.info });
};

export const success = (message) => {
	toast.success(message, { backgroundColor: colors.success });
};

export const warn = (message) => {
	toast.warn(message, { backgroundColor: colors.warn });
};

export const error = (message) => {
	toast.error(message, { backgroundColor: colors.error });
};
