import MainCTA from "../components/forms/fields/MainCTA";

const Message = ({ title = "", message = "" }) => {
	return (
		<div className="message-page flex-center">
			<div className="message-container flex-column">
				<h2 className="m-b-s">{title}</h2>

				<p className="m-b-s">{message}</p>

				<MainCTA
					href="/"
					className="primary m-t-s">
					Proceed
				</MainCTA>
			</div>
		</div>
	);
};

export default Message;
