import { useEffect } from "react";

import ContentLimiter from "../components/ContentLimiter";

import socketRegistry from "../socket/socketRegistry.js";
import {
	useUserStore,
	useTagStore,
	useCallStore,
	useRequestStore,
} from "../store/globalStore.js";
import "react-toastify/dist/ReactToastify.css";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import UserInfoAside from "../components/UserInfoAside.js";

import MatchedConsultant from "../components/users/MatchedConsultant.js";
import InboundRequestsTable from "../components/requests/InboundRequestsTable.js";
import OutboundRequestsTable from "../components/requests/OutboundRequestsTable.js";
import CallsTable from "../components/calls/CallsTable.js";

import { Rating } from "react-simple-star-rating";
import DataTable from "../components/DataTable.js";
import MultiselectField from "../components/forms/fields/MultiselectField.js";

const Home = () => {
	const matchedConsultants = useUserStore(
		(state) => state.matchedConsultants
	);
	const searchConsultants = useUserStore((state) => state.searchConsultants);

	const tags = useTagStore((state) => state.tags);
	const selectedTags = useTagStore((state) => state.selectedTags);
	const setSelectedTags = useTagStore((state) => state.setSelectedTags);
	const fetchTags = useTagStore((state) => state.fetchTags);

	const calls = useCallStore((state) => state.calls);
	const fetchCalls = useCallStore((state) => state.fetchCalls);

	const requests = useRequestStore((state) => state.requests);
	const fetchRequests = useRequestStore((state) => state.fetchRequests);

	useEffect(() => {
		socketRegistry.mapActions({
			REQUEST_CANCELLED: () => {
				fetchRequests();
			},
			REQUEST_ACCEPTED: () => {
				fetchRequests();
			},
			REQUEST_CREATED: () => {
				fetchRequests();
			},
			REQUEST_UPDATED: () => {
				fetchRequests();
			},
			CALL_CREATED: () => {
				fetchCalls();
			},
		});

		fetchTags();
		fetchCalls();
		fetchRequests();
	}, []);

	return (
		<ContentLimiter>
			<div className="col-4 col-m-12 m-t-m">
				<UserInfoAside />
			</div>
			<div className="col-8 col-m-12 m-t-m">
				<div className="p-b-m m-b-m bottom-border">
					<h3 className="m-b-s">Find the person you need</h3>

					<div className="search-container flex-center m-b-xs">
						<MultiselectField
							placeholder="Search for people by tags..."
							value={selectedTags}
							options={tags.map((t) => ({
								value: t.id,
								label: t.value,
							}))}
							onChange={(value) => {
								setSelectedTags(value);
							}}
							// onCreate={(value) => {
							// 	const tags = [
							// 		...selectedTags,
							// 		...value
							// 			.split(" ")
							// 			.map((word) => ({
							// 				label: word.trim().toLowerCase(),
							// 				value: word.trim().toLowerCase(),
							// 			}))
							// 			.filter((word) => word.value.length),
							// 	];

							// 	setSelectedTags(tags);
							// }}
							styles={{
								borderTopLeftRadius: 14,
								borderBottomLeftRadius: 14,
								borderTopRightRadius: 0,
								borderBottomRightRadius: 0,
							}}
						/>

						<button
							className="search-button"
							onClick={searchConsultants}
							disabled={!selectedTags.length}>
							<img
								src="/images/icons/magnifier-white.svg"
								alt=""
							/>
						</button>
					</div>

					{matchedConsultants?.length ? (
						<div>
							<DataTable
								fields={{
									name: {
										text: "Name",
										className: "col-3",
										renderer: (item) => (
											<div className="flex-center">
												<img
													src={item.user.avatar}
													alt=""
													className="avatar m-r-xxs"
												/>

												<span>{item.user.name}</span>
											</div>
										),
									},
									tags: {
										text: "Tags",
										className: "col-6",
										renderer: (item) =>
											item.tags
												.map((t) => t.value)
												.join(", "),
									},
									rating: {
										text: "Rating",
										className: "col-3",
										renderer: (item) => (
											<Rating
												allowFraction
												readonly
												initialValue={3.5}
												size={20}
											/>
										),
									},
								}}
								data={matchedConsultants}
								emptyTableMessage="No consultants found."
								subcontentRenderer={(item) => (
									<MatchedConsultant consultant={item} />
								)}
							/>
						</div>
					) : null}
				</div>

				<Tabs>
					<TabList>
						<Tab>
							Searches (
							{requests?.caller?.length
								? requests.caller.length
								: 0}
							)
						</Tab>
						<Tab>
							Requests (
							{requests?.consultant?.length
								? requests.consultant.length
								: 0}
							)
						</Tab>
						<Tab>Calls ({calls?.length ? calls.length : 0})</Tab>
					</TabList>

					<TabPanel>
						{requests.caller ? (
							<OutboundRequestsTable requests={requests.caller} />
						) : null}
					</TabPanel>
					<TabPanel>
						{requests.consultant ? (
							<InboundRequestsTable
								requests={requests.consultant}
							/>
						) : null}
					</TabPanel>
					<TabPanel>
						{calls ? <CallsTable calls={calls} /> : null}
					</TabPanel>
				</Tabs>
			</div>
		</ContentLimiter>
	);
};

export default Home;
