import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";

import config from "./config";

import AppLayout from "./layouts/AppLayout";
import PublicLayout from "./layouts/PublicLayout";
import { ToastContainer } from "react-toastify";

import Login from "./pages/auth/Login";
import Register from "./pages/auth/Register";
import ForgotPassword from "./pages/auth/ForgotPassword";
import SubmitNewPassword from "./pages/auth/SubmitNewPassword";
import Message from "./pages/Message";
import Home from "./pages/Home";
import Profile from "./pages/Profile";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import Prompt from "./components/Prompt";

const userToken = localStorage.getItem(config.LOCAL_STORAGE_TOKEN_KEY);

if (userToken)
	axios.defaults.headers.common["Authorization"] = `Bearer ${userToken}`;

const App = () => {
	const ProtectedRoute = ({ children }) => {
		if (!localStorage.getItem(config.LOCAL_STORAGE_TOKEN_KEY))
			return (
				<Navigate
					to="/login"
					replace
				/>
			);

		return children;
	};

	return (
		<div>
			<BrowserRouter>
				<Routes>
					<Route
						element={
							<ProtectedRoute>
								<AppLayout />
							</ProtectedRoute>
						}>
						<Route
							path="/"
							element={<Home />}
						/>
					</Route>

					<Route
						element={
							<ProtectedRoute>
								<AppLayout />
							</ProtectedRoute>
						}>
						<Route
							path="/profile"
							element={<Profile />}
						/>
					</Route>

					<Route
						path="/login"
						element={<Login />}
					/>
					<Route
						path="/register"
						element={<Register />}
					/>
					<Route
						path="/forgot-password"
						element={<ForgotPassword />}
					/>
					<Route
						path="/submit-new-password/:resetPasswordSecret"
						element={<SubmitNewPassword />}
					/>
					<Route
						path="/account/reset/finish"
						element={
							<Message
								title="Successfull"
								message="Your password has been reset. Click the button below to proceed to your homepage."
							/>
						}
					/>
					<Route
						path="/account/activation-successfull"
						element={
							<Message
								title="Account activated"
								message="Your account has been activated! Click the button below to proceed to log-in."
							/>
						}
					/>

					<Route
						path="/error"
						element={
							<Message
								title="Error"
								message="An error occurred during your request. Click the button below to go back."
							/>
						}
					/>

					<Route element={<PublicLayout />}>
						<Route
							path="/terms-and-conditions"
							element={<h1>T&C page</h1>}
						/>
					</Route>
				</Routes>
			</BrowserRouter>

			<Prompt />

			<ToastContainer
				position="bottom-right"
				delay={5000}
			/>
		</div>
	);
};

export default App;
