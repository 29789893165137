import { useState } from "react";

const DataTable = ({
	fields,
	data,
	emptyTableMessage = "This table is empty.",
	subcontentRenderer,
}) => {
	const [selectedItem, setSelectedItem] = useState(-1);

	const tableHeader = () => {
		return Object.values(fields).map((field, index) => (
			<div
				key={index}
				className={`column ${field.className}`}>
				<span>{field.text}</span>
			</div>
		));
	};

	const tableBody = () => {
		if (!data || !data.length) {
			return (
				<div className="empty-table-content">
					<span>{emptyTableMessage}</span>
				</div>
			);
		}

		return data.map((item, index) => (
			<div
				key={index}
				className="row">
				<div
					className="row-content"
					onClick={() =>
						setSelectedItem(selectedItem === index ? -1 : index)
					}>
					{Object.keys(fields).map((key, index) => (
						<div
							key={index}
							className={`column ${fields[key].className}`}>
							{fields[key].renderer ? (
								fields[key].renderer(item)
							) : (
								<span>{item[key]}</span>
							)}
						</div>
					))}
				</div>

				{subcontentRenderer && (
					<div
						className={`row-subcontent ${
							index === selectedItem ? "active" : ""
						}`}>
						{subcontentRenderer(item)}
					</div>
				)}
			</div>
		));
	};

	return (
		<div className="data-table">
			<div className="data-table-header">{tableHeader()}</div>

			<div className="data-table-body">{tableBody()}</div>
		</div>
	);
};

export default DataTable;
