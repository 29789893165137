import { useState } from "react";
import ValidatedForm from "../../utils/validation.js";
import CheckboxField from "../../components/forms/fields/CheckboxField.js";
import { openPrompt } from "../../store/globalStore.js";
import MainCTA from "./fields/MainCTA.js";

const ProfileManageNotificationSettings = ({ user, onSubmit = () => null }) => {
	const form = new ValidatedForm();

	form.useState(
		...useState({
			emailNotificationsEnabled: {
				value: user.emailNotificationsEnabled,
				validators: [],
			},
			pushNotificationsEnabled: {
				value: user.pushNotificationsEnabled,
				validators: [],
			},
		})
	);

	return (
		<div>
			<h3 className="m-b-s">Update notification settings</h3>

			<p className="m-b-s">Manage your e-mail and push notifications:</p>

			<div className="m-b-xxs">
				<CheckboxField
					label="Receive e-mails:"
					value={form.state.emailNotificationsEnabled.value}
					onChange={form.set("emailNotificationsEnabled")}
				/>
			</div>
			<div className="m-b-s">
				<CheckboxField
					label="Receive push notifications:"
					value={form.state.pushNotificationsEnabled.value}
					onChange={form.set("pushNotificationsEnabled")}
				/>
			</div>

			<MainCTA
				className="m-t-s"
				disabled={!form.state.isValid}
				onClick={async () => {
					openPrompt({
						title: "Are you sure?",
						message:
							"Are you sure that you want to update your notification settings?",
						onConfirm: async () =>
							await onSubmit({
								emailNotificationsEnabled:
									form.state.emailNotificationsEnabled.value,
								pushNotificationsEnabled:
									form.state.pushNotificationsEnabled.value,
							}),
					});
				}}>
				Update tags
			</MainCTA>
		</div>
	);
};

export default ProfileManageNotificationSettings;
