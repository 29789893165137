import { useState } from "react";
import TextField from "../../components/forms/fields/TextField.js";
import TextareaField from "../../components/forms/fields/TextareaField.js";
import MainCTA from "../../components/forms/fields/MainCTA.js";
import ValidatedForm from "../../utils/validation.js";
import { required, maxLength, domainTypes } from "validatoolkit";
import { openPrompt } from "../../store/globalStore.js";

const ProfilePersonalInformationForm = ({ user, onSubmit = () => null }) => {
	const form = new ValidatedForm();

	form.useState(
		...useState({
			email: {
				value: user.email,
				validators: [required, ...domainTypes.email],
			},
			name: {
				value: user.name,
				validators: [required, ...domainTypes.name],
			},
			description: {
				value: user.description,
				validators: [maxLength(512)],
			},
			calendarLink: {
				value: user.calendarLink,
				validators: [],
			},
		})
	);

	return (
		<form onSubmit={(e) => e.preventDefault()}>
			<h3 className="m-b-s">Change personal information</h3>

			<div className="m-b-xs">
				<TextField
					label="E-mail"
					placeholder="e.g. name@domain.com"
					mandatory
					value={form.state.email.value}
					errorMessage={form.state.email.errorMessage}
					onChange={form.set("email")}
				/>
			</div>

			<div className="m-b-xs">
				<TextField
					label="Name"
					placeholder="e.g. John Smith"
					mandatory
					value={form.state.name.value}
					errorMessage={form.state.name.errorMessage}
					onChange={form.set("name")}
				/>
			</div>

			<div className="m-b-xs">
				<TextareaField
					label="Description"
					placeholder="Write something about yourself..."
					value={form.state.description.value}
					errorMessage={form.state.description.errorMessage}
					onChange={form.set("description")}
				/>
			</div>

			<div className="m-b-xs">
				<TextField
					label="Calendar link"
					placeholder="e.g. Google or Outlook calendar"
					mandatory
					value={form.state.calendarLink.value}
					errorMessage={form.state.calendarLink.errorMessage}
					onChange={form.set("calendarLink")}
				/>
			</div>

			<MainCTA
				className="m-t-xs"
				disabled={!form.state.isValid}
				onClick={async () => {
					openPrompt({
						title: "Are you sure?",
						message:
							"Are you sure that you want to update your personal information?",
						onConfirm: async () =>
							await onSubmit({
								email: form.state.email.value,
								name: form.state.name.value,
								description: form.state.description.value,
								calendarLink: form.state.calendarLink.value,
							}),
					});
				}}>
				Save changes
			</MainCTA>
		</form>
	);
};

export default ProfilePersonalInformationForm;
