export const mapToMultiselectFormat = (tags) => {
	if (!tags?.length) return [];

	return tags?.map((t) => ({
		value: t.id,
		label: t.value,
	}));
};

export const mapFromMultiselectFormat = (tags) => {
	if (!tags?.length) return [];

	return tags?.map((t) => ({
		id: t.value,
		value: t.label,
	}));
};
