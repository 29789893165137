const TextareaField = ({
	label = "",
	placeholder = "",
	errorMessage = "",
	mandatory = false,
	readOnly = false,
	onChange = () => null,
	value = "",
}) => {
	const errorShown = errorMessage && errorMessage.length;

	return (
		<div
			className={`labeled-field-container ${
				errorShown ? "error-shown" : ""
			}`}>
			<textarea
				className="textarea-field"
				autoComplete="off"
				readOnly={readOnly}
				placeholder={placeholder}
				value={value}
				onChange={(event) => onChange(event.target.value)}
				required></textarea>

			{label && label.length && (
				<label className="field-label">
					{label}
					{mandatory && <span className="mandatory"> *</span>}
				</label>
			)}

			{errorShown && (
				<span className="error-message">
					<img
						className="m-r-xxs"
						src="/images/icons/error-exclamation-mark.svg"
						alt=""
					/>
					{errorMessage}
				</span>
			)}
		</div>
	);
};

export default TextareaField;
