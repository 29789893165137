import { useNavigate } from "react-router-dom";
import toast from "../../utils/toast.js";
import { useAccountStore } from "../../store/globalStore.js";
import RegisterForm from "../../components/forms/RegisterForm.js";

const Register = () => {
	const register = useAccountStore((state) => state.register);

	const navigate = useNavigate();

	const submit = async (data) => {
		await register(data);

		toast.success(
			`Verification e-mail sent to ${data.email}! Check your inbox.`
		);

		navigate(`/login`);
	};

	return (
		<div className="auth-page flex-center">
			<RegisterForm onSubmit={submit} />
		</div>
	);
};

export default Register;
