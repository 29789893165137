import Call from "../../components/calls/Call.js";
import DataTable from "../../components/DataTable.js";
import TimeAgo from "javascript-time-ago";

const timeAgo = new TimeAgo("en-US");

const CallsTable = ({ calls }) => {
	return (
		<DataTable
			fields={{
				name: {
					text: "Name",
					className: "col-3",
					renderer: (item) => item.caller.name,
				},
				tags: {
					text: "Tags",
					className: "col-6",
					renderer: (item) =>
						item.tags.map((t) => t.value).join(", "),
				},
				callDateTime: {
					text: "Call created",
					className: "col-3",
					renderer: (item) => (
						<span>{timeAgo.format(new Date(item.createdAt))}</span>
					),
				},
			}}
			data={calls}
			emptyTableMessage="You currently don't have any calls."
			subcontentRenderer={(item) => (
				<Call
					key={item.id}
					call={item}
				/>
			)}
		/>
	);
};

export default CallsTable;
