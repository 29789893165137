import { useState } from "react";
import TextareaField from "../../components/forms/fields/TextareaField.js";
import MainCTA from "../../components/forms/fields/MainCTA.js";
import ValidatedForm from "../../utils/validation.js";
import { required, domainTypes, maxLength } from "validatoolkit";
import { openPrompt } from "../../store/globalStore.js";
import { Rating } from "react-simple-star-rating";

const RateCallForm = ({
	title = "Rate the other person",
	call = null,
	onSubmit = () => null,
}) => {
	const form = new ValidatedForm();

	form.useState(
		...useState({
			rating: {
				value: 0,
				validators: [required, ...domainTypes.rating],
			},
			comment: {
				value: "",
				validators: [required, maxLength(512)],
			},
		})
	);

	return (
		<form onSubmit={(e) => e.preventDefault()}>
			<h4 className="m-b-xs">{title}</h4>

			<Rating
				allowFraction
				initialValue={form.state.rating.value}
				size={20}
				onClick={form.set("rating")}
			/>

			<TextareaField
				label="Your comment:"
				placeholder="Write your impression about the other person..."
				mandatory
				value={form.state.comment.value}
				errorMessage={form.state.comment.errorMessage}
				onChange={form.set("comment")}
			/>

			<MainCTA
				className="m-t-xs"
				disabled={!form.state.isValid}
				onClick={async () => {
					openPrompt({
						title: "Are you sure?",
						message:
							"Are you sure that you want to submit your rating?",
						onConfirm: async () =>
							await onSubmit({
								id: call.id,
								rating: form.state.rating.value,
								comment: form.state.comment.value,
							}),
					});
				}}>
				Submit
			</MainCTA>
		</form>
	);
};

export default RateCallForm;
