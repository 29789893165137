import { useState } from "react";

const PasswordField = ({
	label = "",
	placeholder = "",
	errorMessage = "",
	mandatory = false,
	readOnly = false,
	value = "",
	onChange = () => null,
}) => {
	const [showPassword, setShowPassword] = useState(false);

	const errorShown = errorMessage && errorMessage.length;

	return (
		<div
			className={`labeled-field-container ${
				errorShown ? "error-shown" : ""
			}`}>
			<input
				className="password-field"
				type={showPassword ? "text" : "password"}
				autoComplete="off"
				readOnly={readOnly}
				placeholder={placeholder}
				value={value}
				onChange={(event) => onChange(event.target.value)}
				required
			/>

			<img
				className="reveal-password"
				src={`/images/icons/eye${showPassword ? "-crossed" : ""}.svg`}
				alt=""
				onMouseDown={() => setShowPassword(true)}
				onMouseUp={() => setShowPassword(false)}
			/>

			{label && label.length && (
				<label className="field-label">
					{label}
					{mandatory && <span className="mandatory"> *</span>}
				</label>
			)}

			{errorShown && (
				<span className="error-message">
					<img
						className="m-r-xxs"
						src="/images/icons/error-exclamation-mark.svg"
						alt=""
					/>
					{errorMessage}
				</span>
			)}
		</div>
	);
};

export default PasswordField;
