import { useNavigate } from "react-router-dom";
import toast from "../../utils/toast";
import { useParams } from "react-router-dom";
import { useAccountStore } from "../../store/globalStore.js";
import SubmitNewPasswordForm from "../../components/forms/SubmitNewPasswordForm.js";

const SubmitNewPassword = () => {
	const submitNewPassword = useAccountStore(
		(state) => state.submitNewPassword
	);

	const navigate = useNavigate();
	const params = useParams();

	const submit = async (data) => {
		await submitNewPassword({
			...data,
			resetPasswordSecret: params.resetPasswordSecret,
		});

		toast.info("Password has been successfully updated!");

		navigate("/");
	};

	return (
		<div className="auth-page flex-center">
			<SubmitNewPasswordForm onSubmit={submit} />
		</div>
	);
};

export default SubmitNewPassword;
