import { useRequestStore } from "../../store/globalStore.js";
import { differenceInMinutes } from "../../utils/helpers/dateHelper.js";
import UpdateRequestForm from "../forms/UpdateRequestForm.js";

const OutboundRequest = ({ request }) => {
	const acceptRequest = useRequestStore((state) => state.acceptRequest);
	const updateRequest = useRequestStore((state) => state.updateRequest);
	const cancelRequest = useRequestStore((state) => state.cancelRequest);

	let callDurationInMinutes;

	if (request.startDateTime && request.endDateTime) {
		callDurationInMinutes = differenceInMinutes(
			request.startDateTime,
			request.endDateTime
		);
	}

	return (
		<div className="subcontent-card">
			<div className="flex-distributed-row m-t-xs m-b-s">
				<div className="request-card-user-info">
					<div>
						<img
							className="avatar"
							src={request.caller.avatar}
							alt=""
						/>
					</div>
					<div>
						<h4>{request.caller.name}</h4>
						{request.pricePerMinute ? (
							<h6 className="gray-text">
								{request.pricePerMinute.toFixed(2)}$/min
							</h6>
						) : null}
					</div>
				</div>

				{callDurationInMinutes ? (
					<div>
						{request.pricePerMinute ? (
							<h4 className="primary-color-text">
								{(
									callDurationInMinutes *
									request.pricePerMinute
								).toFixed(2)}
								$
							</h4>
						) : null}
						<h6 className="gray-text">
							{callDurationInMinutes}min
						</h6>
					</div>
				) : null}
			</div>

			<p className="m-b-s">
				{request.tags.map((t) => (
					<span
						key={t.id}
						className="request-tag">
						{t.value}
					</span>
				))}
			</p>

			{request.caller.calendarLink ? (
				<div className="m-b-s">
					<a href={request.caller.calendarLink}>Caller calendar</a>
				</div>
			) : null}

			{request && !request.blocked ? (
				<UpdateRequestForm
					request={request}
					onSubmit={updateRequest}
					onAccept={acceptRequest}
					onCancel={cancelRequest}
				/>
			) : null}
		</div>
	);
};

export default OutboundRequest;
