import Avatar from "boring-avatars";
import config from "../config";
import timeAgo from "../utils/timeAgo";
import { useUserStore } from "../store/globalStore.js";

const UserInfoAside = () => {
	const userInfo = useUserStore((state) => state.userInfo);

	return (
		<div className="flex-centered-column">
			<div className="avatar-container m-b-xs">
				{userInfo?.avatar ? (
					<div
						className="avatar-image"
						style={{
							backgroundImage: `url(${userInfo.avatar})`,
						}}></div>
				) : null}

				{!userInfo?.avatar && userInfo?.id ? (
					<Avatar
						size={"100%"}
						name={userInfo.id}
						variant="beam"
						colors={[
							config.COLORS.BLUE,
							config.COLORS.LIGHT_BLUE,
							config.COLORS.GRAY,
							config.COLORS.WHITE,
						]}
					/>
				) : null}
			</div>

			{userInfo?.name ? (
				<span className="banner-track gray medium">
					{userInfo.name}
				</span>
			) : null}

			{userInfo?.createdAt ? (
				<span className="banner-track primary small">
					Joined {timeAgo.format(new Date(userInfo.createdAt))}
				</span>
			) : null}

			{userInfo?.balance ? (
				<span className="m-t-xs">{userInfo.balance} tokens left</span>
			) : null}
		</div>
	);
};

export default UserInfoAside;
