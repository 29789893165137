export default class ValidatedForm {
	/**
	 * Initialization method for the form.
	 *
	 * @param {*} state React hooks state of the form.
	 * @param {*} setState React hooks setState of the form.
	 */
	useState = (state, setState) => {
		// Set state and setState React hooks function.
		this.state = state;
		this.setState = setState;

		// Check right away if form submit should be enabled (if isValid is manually set to true on fields).
		this.checkIfFormSubmitIsEnabled();
	};

	/**
	 * Sets the value under a specific key and executes the main validation logic for the form afterwards.
	 *
	 * @param {*} key key of the field that needs to be set.
	 */
	set = (key) => (value) => {
		// Find the wanted field by the key.
		const field = this.state[key];

		// Set it's value to the provided value and make it initially valid.
		field.value = value;
		field.isValid = true;

		// If there are validators, validate the new value through each one.
		if (field.validators) {
			field.validators.every((validator) => {
				const result = validator(value);

				field.isValid = result.isValid;
				field.errorMessage = result.isValid
					? undefined
					: result.message;

				return field.isValid;
			});
		}

		// If other fields are added to "alsoValidate" they also need to be validated in this step.
		field?.alsoValidate?.forEach((key) => {
			const otherField = this.state[key];

			otherField?.validators.every((validator) => {
				const result = validator(otherField.value);

				otherField.isValid = result.isValid;
				otherField.errorMessage = result.isValid
					? undefined
					: result.message;

				return otherField.isValid;
			});
		});

		// Whenever something is changed in the form, all fields need to be validated, but error messages shouldn't be shown.
		this.validateEachField();

		// Re-render the component with React setState.
		this.setState({ ...this.state });
	};

	update = (data) => {
		Object.keys(data).forEach((key) => {
			if (this.state[key] && data[key] !== this.state[key].value) {
				this.set(key)(data[key]);
			}
		});
	};

	/**
	 * Checks if fields in form are valid and sets isValid on them.
	 */
	validateEachField = () => {
		// Iterate through each key and validate the field, but don't set the error message.
		Object.keys(this.state)
			.filter((item) => item !== "isValid")
			.forEach((key) => {
				const field = this.state[key];

				field.isValid = true;

				field?.validators?.forEach((validator) => {
					const result = validator(field.value);

					field.isValid = field.isValid && result.isValid;
				});
			});

		// In the end, check if form submit should be enabled.
		this.checkIfFormSubmitIsEnabled();
	};

	/**
	 * Checks if form submit button should be enabled.
	 */
	checkIfFormSubmitIsEnabled = () => {
		// Determine if the entire form is valid (skip the isValid property, because it's not an actual form field).
		this.state.isValid = Object.keys(this.state)
			.filter((item) => item !== "isValid")
			.map((key) => !!this.state[key].isValid)
			.reduce((a, b) => a && b);
	};

	/**
	 * This function is an addition to standard validation functions,
	 * since it uses state to compare the provided value to another value from the state.
	 *
	 * @param {*} key Key of the other field to which the value should be compared.
	 * @returns a standard validator response with isValid and error message.
	 */
	isEqual = (key) => {
		return (value) => {
			return {
				isValid: value === this.state[key].value,
				message: "Fields do not match.",
			};
		};
	};
}
