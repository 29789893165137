import { Rating } from "react-simple-star-rating";
import { useRequestStore, openPrompt } from "../../store/globalStore.js";
import MainCTA from "../../components/forms/fields/MainCTA";

const MatchedConsultant = ({ consultant }) => {
	const createRequest = useRequestStore((state) => state.createRequest);

	return (
		<div className="matched-consultant p-s m-b-xs">
			<div className="m-b-s">
				<h4>{consultant.user.name}</h4>
				<h6 className="m-b-xs">
					{consultant.tags.map((t) => t.value).join(", ")}
				</h6>

				<Rating
					onClick={(rate) => null}
					onPointerMove={(value, index) => null}
					allowFraction
					readonly
					initialValue={3.5}
					size={20}
				/>
			</div>

			<div>
				<MainCTA
					onClick={async () =>
						openPrompt({
							title: "Are you sure?",
							message:
								"Are you sure that you want to request a call with this person?",
							onConfirm: async () =>
								await createRequest(
									consultant.user.id,
									consultant.requestedTagIds
								),
						})
					}>
					Call
				</MainCTA>
			</div>
		</div>
	);
};

export default MatchedConsultant;
