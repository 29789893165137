import { useState } from "react";
import TextareaField from "../../components/forms/fields/TextareaField.js";
import MainCTA from "../../components/forms/fields/MainCTA.js";
import ValidatedForm from "../../utils/validation.js";
import { required, maxLength } from "validatoolkit";
import { openPrompt } from "../../store/globalStore.js";

const ReportUserForm = ({ reportedUser = null, onSubmit = () => null }) => {
	const form = new ValidatedForm();

	form.useState(
		...useState({
			message: {
				value: "",
				validators: [required, maxLength(512)],
			},
		})
	);

	return (
		<form onSubmit={(e) => e.preventDefault()}>
			<h4 className="m-b-xs">Report {reportedUser.name}</h4>

			<div className="m-b-xs">
				<TextareaField
					label="Report message:"
					placeholder="Write a reason for your report..."
					mandatory
					value={form.state.message.value}
					errorMessage={form.state.message.errorMessage}
					onChange={form.set("message")}
				/>
			</div>

			<MainCTA
				onClick={async () => {
					openPrompt({
						title: "Are you sure?",
						message:
							"Are you sure that you want to report this user?",
						onConfirm: async () =>
							await onSubmit({
								reportedUserId: reportedUser.id,
								message: form.state.message.value,
							}),
					});
				}}>
				Report user
			</MainCTA>
		</form>
	);
};

export default ReportUserForm;
