import { useState } from "react";
import MainCTA from "../../components/forms/fields/MainCTA.js";
import ValidatedForm from "../../utils/validation.js";
import { required } from "validatoolkit";
import { openPrompt } from "../../store/globalStore.js";
import TextField from "./fields/TextField.js";

const ProfileManageIPSForm = ({
	ips = null,
	readOnly = false,
	onSubmit = () => null,
}) => {
	const form = new ValidatedForm();

	form.useState(
		...useState({
			n: {
				value: ips?.n,
				validators: [required],
			},
			sf: {
				value: ips?.sf,
				validators: [required],
			},
			r: {
				value: ips?.r,
				validators: [required],
			},
			k: {
				value: ips?.k,
				validators: [required],
			},
			v: {
				value: ips?.v,
				validators: [required],
			},
			c: {
				value: ips?.c,
				validators: [required],
			},
		})
	);

	return (
		<form onSubmit={(e) => e.preventDefault()}>
			<h4 className="m-b-s">Manage default IPS information</h4>

			<p className="m-b-m">
				IPS payments are only applicable for Serbia. If you are not from
				Serbia, arrange a different payment channel with the other
				person.
			</p>

			<div>
				<div className="col-12 m-b-xs">
					<TextField
						// label="Naziv primaoca:"
						label="Recipient name:"
						placeholder="e.g. EPS Snabdevanje 11000 Beograd"
						mandatory
						value={form.state.n.value}
						errorMessage={form.state.n.errorMessage}
						onChange={form.set("n")}
						readOnly={readOnly}
					/>
				</div>
				<div className="col-12 m-b-xs">
					<TextField
						// label="Racun primaoca:"
						label="Recipient bank account:"
						placeholder="e.g. 845000000014284968"
						mandatory
						value={form.state.r.value}
						errorMessage={form.state.r.errorMessage}
						onChange={form.set("r")}
						readOnly={readOnly}
					/>
				</div>
			</div>

			<div>
				<div className="col-3 col-m-6 m-b-xs">
					<TextField
						// label="Sifra placanja:"
						label="Payment code:"
						placeholder="e.g. 221"
						mandatory
						value={form.state.sf.value}
						errorMessage={form.state.sf.errorMessage}
						onChange={form.set("sf")}
						readOnly={readOnly}
					/>
				</div>
				<div className="col-3 col-m-6 m-b-xs">
					<TextField
						// label="Kod:"
						label="Code:"
						placeholder="e.g. PR"
						mandatory
						value={form.state.k.value}
						errorMessage={form.state.k.errorMessage}
						onChange={form.set("k")}
						readOnly={readOnly}
					/>
				</div>
				<div className="col-3 col-m-6 m-b-xs">
					<TextField
						// label="Verzija:"
						label="Version:"
						placeholder="e.g. 01"
						mandatory
						value={form.state.v.value}
						errorMessage={form.state.v.errorMessage}
						onChange={form.set("v")}
						readOnly={readOnly}
					/>
				</div>
				<div className="col-3 col-m-6 m-b-xs">
					<TextField
						// label="Znakovni skup:"
						label="Character set:"
						placeholder="e.g. 1"
						mandatory
						value={form.state.c.value}
						errorMessage={form.state.c.errorMessage}
						onChange={form.set("c")}
						readOnly={readOnly}
					/>
				</div>
			</div>

			<MainCTA
				className="m-t-xs"
				disabled={!form.state.isValid}
				onClick={async () => {
					openPrompt({
						title: "Are you sure?",
						message:
							"Are you sure that you want to submit your IPS information?",
						onConfirm: async () =>
							await onSubmit({
								n: form.state.n.value,
								sf: form.state.sf.value,
								r: form.state.r.value,
								k: form.state.k.value,
								v: form.state.v.value,
								c: form.state.c.value,
							}),
					});
				}}>
				Save IPS
			</MainCTA>
		</form>
	);
};

export default ProfileManageIPSForm;
