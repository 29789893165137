import { useState } from "react";
import { required, domainTypes } from "validatoolkit";
import TextField from "../../components/forms/fields/TextField";
import PasswordField from "../../components/forms/fields/PasswordField";
import MainCTA from "../../components/forms/fields/MainCTA.js";
import ValidatedForm from "../../utils/validation.js";

const RegisterForm = ({ onSubmit = () => null }) => {
	const form = new ValidatedForm();

	form.useState(
		...useState({
			email: {
				value: "",
				validators: [required, ...domainTypes.email],
			},
			name: {
				value: "",
				validators: [required, ...domainTypes.name],
			},
			password: {
				value: "",
				validators: [required, ...domainTypes.password],
				alsoValidate: ["repeatPassword"],
			},
			repeatPassword: {
				value: "",
				validators: [form.isEqual("password")],
			},
		})
	);

	return (
		<form
			className="auth-form flex-column"
			onSubmit={(e) => e.preventDefault()}>
			<div className="flex-vertical-center flex-row m-b-s">
				<img
					src="/images/icon-48.png"
					alt=""
				/>
				<h2 className="m-l-xs">Join us</h2>
			</div>

			<div className="m-b-xs">
				<TextField
					label="E-mail"
					placeholder="e.g. name@domain.com"
					value={form.state.email.value}
					errorMessage={form.state.email.errorMessage}
					onChange={form.set("email")}
				/>
			</div>

			<div className="m-b-xs">
				<TextField
					label="Full name"
					placeholder="e.g. hadrbari024"
					value={form.state.name.value}
					errorMessage={form.state.name.errorMessage}
					onChange={form.set("name")}
				/>
			</div>

			<div className="m-b-xs">
				<PasswordField
					label="Password"
					placeholder="e.g. *******"
					value={form.state.password.value}
					errorMessage={form.state.password.errorMessage}
					onChange={form.set("password")}
				/>
			</div>

			<div className="m-b-xs">
				<PasswordField
					label="Repeat password"
					placeholder="e.g. *******"
					value={form.state.repeatPassword.value}
					errorMessage={form.state.repeatPassword.errorMessage}
					onChange={form.set("repeatPassword")}
				/>
			</div>

			<span className="disclaimer">
				If you already have an account, log in <a href="/login">here</a>
				.
			</span>

			<MainCTA
				className="primary m-t-s"
				onClick={() =>
					onSubmit({
						email: form.state.email.value,
						name: form.state.name.value,
						password: form.state.password.value,
					})
				}
				disabled={!form.state.isValid}>
				Register
			</MainCTA>
		</form>
	);
};

export default RegisterForm;
