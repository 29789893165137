import { useState } from "react";
import moment from "moment";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";

const DateTimeField = ({
	label = "",
	min = null,
	max = null,
	placeholder = "",
	errorMessage = "",
	mandatory = false,
	readOnly = false,
	onChange = () => null,
	value = new Date(),
}) => {
	const [renderFlag, setRenderFlag] = useState(false);
	const errorShown = errorMessage && errorMessage.length;

	// Used to block invalid dates.
	const isDateBlocked = (date) => {
		let isValid = true;

		if (min && min instanceof Date)
			isValid = isValid && date.isAfter(moment(min));

		if (max && max instanceof Date)
			isValid = isValid && date.isBefore(moment(max));

		return isValid;
	};

	return (
		<div
			className={`date-time-picker labeled-field-container ${
				errorShown ? "error-shown" : ""
			}`}>
			<Datetime
				key={renderFlag}
				isValidDate={isDateBlocked}
				initialValue={value}
				input={false}
				open={true}
				inputProps={{
					placeholder: placeholder,
					disabled: readOnly,
					onBlur: (e) => {
						const date = moment(
							e.target.value,
							"MM/DD/YYYY h:mm a",
							true
						);

						if (!date._isValid) {
							setRenderFlag(!renderFlag);
						}
					},
				}}
				onChange={(value) => {
					if (value._isValid) {
						onChange(value.toDate());
					}
				}}
			/>

			{label && label.length && (
				<label className="field-label">
					{/* {label} */}
					{value.toString()}
					{mandatory && <span className="mandatory"> *</span>}
				</label>
			)}

			{errorShown && (
				<span className="error-message">
					<img
						className="m-r-xxs"
						src="/images/icons/error-exclamation-mark.svg"
						alt=""
					/>
					{errorMessage}
				</span>
			)}
		</div>
	);
};

export default DateTimeField;
