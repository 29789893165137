import CreatableSelect from "react-select/creatable";
import makeAnimated from "react-select/animated";

const animatedComponents = makeAnimated();

const MultiselectField = ({
	placeholder = "",
	disabled = false,
	onCreate = () => null,
	onChange = () => null,
	value = "",
	options = [],
	styles = {},
}) => {
	return (
		<CreatableSelect
			isMulti
			onCreateOption={onCreate}
			onChange={onChange}
			closeMenuOnSelect={false}
			components={animatedComponents}
			isDisabled={disabled}
			defaultValue={value}
			options={options}
			placeholder={placeholder}
			styles={{
				control: (baseStyles, state) => ({
					...baseStyles,
					minHeight: 54,
					border: state.isFocused ? "1px solid #ddd" : "none",
					boxShadow: "none",
					fontFamily: "Outfit",
					fontSize: "0.9rem",
					borderRadius: 14,
					...styles,
				}),
				option: (baseStyles) => ({
					...baseStyles,
					fontSize: "0.9rem",
				}),
			}}
		/>
	);
};

export default MultiselectField;
