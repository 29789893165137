import { useState } from "react";
import ValidatedForm from "../../utils/validation.js";
import { isArray } from "validatoolkit";
import CheckboxField from "../../components/forms/fields/CheckboxField.js";
import { CallPlatforms } from "../../utils/constants.js";
import { openPrompt } from "../../store/globalStore.js";
import MainCTA from "./fields/MainCTA.js";

const ProfileManageCallPlatformsForm = ({ user, onSubmit = () => null }) => {
	const form = new ValidatedForm();

	form.useState(
		...useState({
			callPlatforms: {
				value: user.callPlatforms,
				validators: [isArray],
			},
		})
	);

	const toggleCallPlatform = (platform, value) => {
		if (value) {
			const updatedPlatforms = [
				...form.state.callPlatforms.value,
				platform,
			];

			form.set("callPlatforms")(
				updatedPlatforms.filter(
					(value, index, array) => array.indexOf(value) === index
				)
			);
		} else {
			form.set("callPlatforms")(
				form.state.callPlatforms.value.filter((p) => p !== platform)
			);
		}
	};

	return (
		<div>
			<h3 className="m-b-s">Update call platforms</h3>

			<p className="m-b-s">Mark the call platforms that you use:</p>

			<div className="m-b-xxs">
				<CheckboxField
					label="Google Meet"
					value={form.state.callPlatforms.value.includes(
						CallPlatforms.GoogleMeet
					)}
					onChange={(value) =>
						toggleCallPlatform(CallPlatforms.GoogleMeet, value)
					}
				/>
			</div>
			<div className="m-b-xxs">
				<CheckboxField
					label="Microsoft Teams"
					value={form.state.callPlatforms.value.includes(
						CallPlatforms.MicrosoftTeams
					)}
					onChange={(value) =>
						toggleCallPlatform(CallPlatforms.MicrosoftTeams, value)
					}
				/>
			</div>
			<div className="m-b-s">
				<CheckboxField
					label="Zoom"
					value={form.state.callPlatforms.value.includes(
						CallPlatforms.Zoom
					)}
					onChange={(value) =>
						toggleCallPlatform(CallPlatforms.Zoom, value)
					}
				/>
			</div>

			<MainCTA
				className="m-t-s"
				disabled={!form.state.isValid}
				onClick={async () => {
					openPrompt({
						title: "Are you sure?",
						message:
							"Are you sure that you want to update your call platforms?",
						onConfirm: async () =>
							await onSubmit({
								callPlatforms: form.state.callPlatforms.value,
							}),
					});
				}}>
				Update tags
			</MainCTA>
		</div>
	);
};

export default ProfileManageCallPlatformsForm;
