import toast from "../../utils/toast";
import { useAccountStore } from "../../store/globalStore.js";
import ForgotPasswordForm from "../../components/forms/ForgotPasswordForm.js";

const ForgotPassword = () => {
	const forgotPassword = useAccountStore((state) => state.forgotPassword);

	const submit = async (data) => {
		await forgotPassword(data);

		toast.info(
			"Message has been sent to your e-mail addres! Check your inbox."
		);
	};

	return (
		<div className="auth-page flex-center">
			<ForgotPasswordForm onSubmit={submit} />
		</div>
	);
};

export default ForgotPassword;
