import { useState, useEffect } from "react";
import MainCTA from "../../components/forms/fields/MainCTA.js";
import DateTimeField from "../../components/forms/fields/DateTimeField.js";
import TextField from "../../components/forms/fields/TextField";
import NumberField from "../../components/forms/fields/NumberField";
import ValidatedForm from "../../utils/validation.js";
import { isUrl, isNumeric } from "validatoolkit";
import { openPrompt } from "../../store/globalStore.js";

const UpdateRequestForm = ({
	request,
	onSubmit = () => null,
	onAccept = () => null,
	onCancel = () => null,
}) => {
	const form = new ValidatedForm();

	form.useState(
		...useState({
			startDateTime: {
				value: request.startDateTime
					? new Date(request.startDateTime)
					: undefined,
				validators: [],
			},
			endDateTime: {
				value: request.endDateTime
					? new Date(request.endDateTime)
					: undefined,
				validators: [],
			},
			callLink: {
				value: request.callLink,
				validators: [isUrl],
			},
			pricePerMinute: {
				value: request.pricePerMinute,
				validators: [isNumeric],
			},
		})
	);

	useEffect(() => {
		form.update(request);
	}, [request]);

	return (
		<form onSubmit={(e) => e.preventDefault()}>
			<div className="m-b-s">
				<div className="col-9 col-m-12 m-b-xs">
					<TextField
						label="Call link:"
						mandatory
						value={form.state.callLink.value}
						errorMessage={form.state.callLink.errorMessage}
						onChange={form.set("callLink")}
					/>
				</div>

				<div className="col-3 col-m-12 m-b-xs">
					<NumberField
						label="Price per minute"
						mandatory
						value={form.state.pricePerMinute.value}
						min={0}
						errorMessage={form.state.pricePerMinute.errorMessage}
						onChange={form.set("pricePerMinute")}
					/>
				</div>
				<div className="col-6 col-m-12 m-b-xs">
					<DateTimeField
						label="From:"
						mandatory
						value={form.state.startDateTime.value}
						errorMessage={form.state.startDateTime.errorMessage}
						onChange={form.set("startDateTime")}
					/>
				</div>
				<div className="col-6 col-m-12 m-b-xs">
					<DateTimeField
						label="To:"
						mandatory
						value={form.state.endDateTime.value}
						errorMessage={form.state.endDateTime.errorMessage}
						onChange={form.set("endDateTime")}
					/>
				</div>
			</div>

			<div className="request-buttons">
				<MainCTA
					className="primary m-r-xs"
					disabled={request.acceptedByConsultant}
					onClick={async () => {
						openPrompt({
							title: "Are you sure?",
							message:
								"Are you sure that you want to accept this request?",
							onConfirm: async () => await onAccept(request.id),
						});
					}}>
					Accept{request.acceptedByConsultant ? "ed" : null}
				</MainCTA>

				<MainCTA
					className="primary m-r-xs"
					disabled={!form.state.isValid}
					onClick={() =>
						openPrompt({
							title: "Are you sure?",
							message:
								"Are you sure that you want to update this request?",
							onConfirm: async () => {
								await onSubmit({
									...request,
									startDateTime:
										form.state.startDateTime.value,
									endDateTime: form.state.endDateTime.value,
									callLink: form.state.callLink.value,
									pricePerMinute:
										form.state.pricePerMinute.value,
								});
							},
						})
					}>
					Update
				</MainCTA>

				<MainCTA
					className="primary negative"
					onClick={() =>
						openPrompt({
							title: "Are you sure?",
							message:
								"Are you sure that you want to cancel this request?",
							onConfirm: async () => await onCancel(request.id),
						})
					}
					disabled={request.cancelledBy}>
					Cancel{request.cancelledBy ? "ed" : null}
				</MainCTA>
			</div>
		</form>
	);
};

export default UpdateRequestForm;
