import { useState, useEffect } from "react";
import ContentLimiter from "../components/ContentLimiter";
import MainCTA from "../components/forms/fields/MainCTA.js";
import UserInfoAside from "../components/UserInfoAside.js";
import {
	useAccountStore,
	useUserStore,
	useTagStore,
	openPrompt,
	useModalStore,
	usePaymentsStore,
	signOut,
} from "../store/globalStore.js";
import ProfilePersonalInformationForm from "../components/forms/ProfilePersonalInformationForm.js";
import ProfileManageTagsForm from "../components/forms/ProfileManageTagsForm.js";
import Resizer from "react-image-file-resizer";
import toast from "../utils/toast.js";
import ProfileManageCallPlatformsForm from "../components/forms/ProfileManageCallPlatformsForm.js";
import ProfileManageIPSForm from "../components/forms/ProfileManageIPSForm.js";
import ProfileManageNotificationSettings from "../components/forms/ProfileManageNotificationSettings.js";
import Modal from "../components/Modal.js";
import BuyTokensForm from "../components/forms/BuyTokensForm.js";

const Profile = () => {
	const updatePersonalInformation = useAccountStore(
		(state) => state.updatePersonalInformation
	);
	const updateTags = useAccountStore((state) => state.updateTags);
	const updateNotificationSettings = useAccountStore(
		(state) => state.updateNotificationSettings
	);
	const updateCallPlatforms = useAccountStore(
		(state) => state.updateCallPlatforms
	);
	const updateIPS = useAccountStore((state) => state.updateIPS);
	const updateAvatar = useAccountStore((state) => state.updateAvatar);
	const removeAvatar = useAccountStore((state) => state.removeAvatar);
	const deleteAccount = useAccountStore((state) => state.deleteAccount);

	const userInfo = useUserStore((state) => state.userInfo);
	const tags = useTagStore((state) => state.tags);
	const fetchTags = useTagStore((state) => state.fetchTags);

	const tokenModalOpen = useModalStore((state) => state.tokenModalOpen);
	const setTokenModalOpen = useModalStore((state) => state.setTokenModalOpen);

	const paymentAccountInfo = usePaymentsStore(
		(state) => state.paymentAccountInfo
	);
	const fetchPaymentAccountInfo = usePaymentsStore(
		(state) => state.fetchPaymentAccountInfo
	);

	const [file, setFile] = useState();

	const resizeFile = (file) =>
		new Promise((resolve) => {
			const mimeMap = {
				"image/png": "png",
				"image/jpeg": "jpeg",
				"image/jpg": "jpg",
				"image/webp": "webp",
			};

			const extension = mimeMap[file.type];

			if (!extension) throw new Error("Unsupported file extension.");

			Resizer.imageFileResizer(
				file,
				300,
				300,
				extension,
				100,
				0,
				(uri) => {
					resolve(uri);
				},
				"file"
			);
		});

	const handleChange = async (event) => {
		const resizedImage = await resizeFile(event.target.files[0]);

		setFile(resizedImage);
	};

	const submit = async () => {
		const formData = new FormData();

		formData.append("file", file);

		await updateAvatar(formData);
	};

	const remove = async () => {
		await removeAvatar();
	};

	const onPersonalInformationSubmit = async (data) => {
		await updatePersonalInformation(data);

		// TODO: Not the best option for re-verification (maybe redirect?).
		if (data.email !== userInfo.email) {
			toast.info("You need to verify your e-mail again. Signing out...");

			setTimeout(() => signOut(), 3000);
		}
	};

	const onUpdateTags = async (data) => {
		await updateTags(data);
	};

	const onUpdateNotificationSettings = async (data) => {
		await updateNotificationSettings(data);
	};

	const onUpdateCallPlatforms = async (data) => {
		await updateCallPlatforms(data);
	};

	const onIPSSubmit = async (data) => {
		await updateIPS(data);
	};

	useEffect(() => {
		fetchTags();
		fetchPaymentAccountInfo();
	}, []);

	if (!userInfo || !tags) return null;

	return (
		<ContentLimiter>
			<div className="col-4 col-m-12 m-t-m">
				<UserInfoAside />
			</div>
			<div className="col-8 col-m-12 m-t-m">
				<div className="p-b-m m-b-m bottom-border">
					<input
						className="btn"
						type="file"
						onChange={handleChange}
					/>

					<MainCTA onClick={submit}>Submit</MainCTA>

					<MainCTA onClick={remove}>Remove</MainCTA>
				</div>

				<div className="p-b-m m-b-m bottom-border">
					<h3 className="m-b-s">Buy tokens</h3>

					<p className="m-b-s">
						In order to buy tokens, click on the button bellow:
					</p>

					{tokenModalOpen ? (
						<Modal
							title="Buy tokens"
							onClose={() => setTokenModalOpen(false)}>
							<BuyTokensForm
								user={userInfo}
								paymentAccountInfo={paymentAccountInfo}
							/>
						</Modal>
					) : null}

					<MainCTA onClick={() => setTokenModalOpen(true)}>
						Buy tokens
					</MainCTA>
				</div>

				<div className="p-b-m m-b-m bottom-border">
					<ProfilePersonalInformationForm
						user={userInfo}
						onSubmit={onPersonalInformationSubmit}
					/>
				</div>

				{tags ? (
					<div className="p-b-m m-b-m bottom-border">
						<ProfileManageTagsForm
							user={userInfo}
							tags={tags}
							onSubmit={onUpdateTags}
						/>
					</div>
				) : null}

				<div className="p-b-m m-b-m bottom-border">
					<ProfileManageCallPlatformsForm
						user={userInfo}
						onSubmit={onUpdateCallPlatforms}
					/>
				</div>

				<div className="p-b-m m-b-m bottom-border">
					<ProfileManageNotificationSettings
						user={userInfo}
						onSubmit={onUpdateNotificationSettings}
					/>
				</div>

				<div className="p-b-m m-b-m bottom-border">
					<ProfileManageIPSForm
						ips={userInfo.ips}
						onSubmit={onIPSSubmit}
					/>
				</div>

				<div className="p-b-m m-b-m bottom-border">
					<h3 className="m-b-s">Change password</h3>

					<p className="m-b-s">
						In order to change your password, click on the button
						bellow:
					</p>

					<MainCTA
						className="m-t-s"
						onClick={() =>
							window.open(
								`/submit-new-password/${userInfo?.resetPasswordSecret}`,
								"_blank"
							)
						}>
						Change password
					</MainCTA>
				</div>

				<div className="p-b-m">
					<h3 className="m-b-s">Delete account</h3>

					<p className="m-b-s">
						If you want to delete your account, click on the button
						bellow:
					</p>

					<MainCTA
						className="red m-t-s"
						onClick={async () => {
							openPrompt({
								title: "Are you sure?",
								message:
									"Are you sure that you want to delete your account? All your data, including your ratings will be lost!",
								onConfirm: async () => await deleteAccount(),
								confirmButtonText: "I'm sure",
								confirmButtonClassName: "red",
								cancelButtonClassName: "red",
							});
						}}>
						Delete account
					</MainCTA>
				</div>
			</div>
		</ContentLimiter>
	);
};

export default Profile;
