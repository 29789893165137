import InboundRequest from "../../components/requests/InboundRequest.js";
import DataTable from "../../components/DataTable.js";
import TimeAgo from "javascript-time-ago";

const timeAgo = new TimeAgo("en-US");

const InboundRequestsTable = ({ requests }) => {
	return (
		<DataTable
			fields={{
				name: {
					text: "Name",
					className: "col-4",
					renderer: (item) => (
						<div className="flex-column">
							<span>{item.caller.name}</span>
							<span className="smaller-text">
								{timeAgo.format(new Date(item.createdAt))}
							</span>
						</div>
					),
				},
				tags: {
					text: "Tags",
					className: "col-6",
					renderer: (item) =>
						item.tags.map((t) => t.value).join(", "),
				},
				actions: {
					text: "",
					className: "col-2",
					renderer: (item) => (
						// <div
						// 	className="request-status waiting"
						// 	title={`Waiting for ${item.caller.name} to accept the request.`}>
						// 	Waiting
						// </div>

						<div
							className="request-status ready"
							title={`Request is ready to be accepted.`}>
							Ready
						</div>

						// <div
						// 	className="request-status cancelled"
						// 	title={`Request has been cancelled by ${item.caller.name}.`}>
						// 	Cancelled
						// </div>
					),
				},
			}}
			data={requests}
			emptyTableMessage="You currently don't have any inbound requests."
			subcontentRenderer={(item) => (
				<InboundRequest
					key={item.id}
					request={item}
				/>
			)}
		/>
	);
};

export default InboundRequestsTable;
