import { create } from "zustand";
import axios from "axios";
import config from "../config.js";
import toast from "../utils/toast.js";

axios.interceptors.response.use(undefined, (error) => {
	toast.error(error.response.data || error.message);

	return Promise.reject(error);
});

export const signOut = () => {
	localStorage.removeItem(config.LOCAL_STORAGE_TOKEN_KEY);

	window.location.reload();
};

export const useAccountStore = create((set, get) => ({
	login: async (data) => {
		const { data: token } = await axios.post(
			`${config.BASE_API_PATH}/account/login`,
			data
		);

		localStorage.setItem(config.LOCAL_STORAGE_TOKEN_KEY, token);

		axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
	},
	register: async (data) => {
		await axios.post(`${config.BASE_API_PATH}/account/register`, data);
	},
	forgotPassword: async (data) => {
		await axios.post(
			`${config.BASE_API_PATH}/account/forgot-password`,
			data
		);
	},
	submitNewPassword: async (data) => {
		await axios.post(
			`${config.BASE_API_PATH}/account/submit-new-password`,
			data
		);
	},
	updateIPS: async (data) => {
		await axios.patch(`${config.BASE_API_PATH}/account/profile/ips`, data);

		useUserStore.getState().fetchUserInfo();
	},
	updateAvatar: async (data) => {
		await axios.put(
			`${config.BASE_API_PATH}/account/profile/avatar`,
			data,
			{
				headers: {
					"content-type": "multipart/form-data",
				},
			}
		);

		useUserStore.getState().fetchUserInfo();
	},
	updatePersonalInformation: async (data) => {
		await axios.patch(`${config.BASE_API_PATH}/account/profile/info`, data);

		useUserStore.getState().fetchUserInfo();
	},
	updateNotificationSettings: async (data) => {
		await axios.patch(
			`${config.BASE_API_PATH}/account/profile/notifications`,
			data
		);

		useUserStore.getState().fetchUserInfo();
	},
	updateTags: async (data) => {
		await axios.put(`${config.BASE_API_PATH}/account/profile/tags`, data);

		useUserStore.getState().fetchUserInfo();
	},
	updateCallPlatforms: async (data) => {
		await axios.put(
			`${config.BASE_API_PATH}/account/profile/platforms`,
			data
		);

		useUserStore.getState().fetchUserInfo();
	},
	removeAvatar: async () => {
		await axios.delete(`${config.BASE_API_PATH}/account/profile/avatar`);

		useUserStore.getState().fetchUserInfo();
	},
	deleteAccount: async () => {
		await axios.delete(`${config.BASE_API_PATH}/account`);

		toast.info("Account deleted! Signing out...");

		setTimeout(() => signOut(), 3000);
	},
}));

export const useCallStore = create((set, get) => ({
	calls: [],
	setCalls: (calls) => set((state) => ({ calls })),
	addCall: (call) => {
		const calls = [call, ...get().calls]; // TODO: Check why it only adds one.

		return set((state) => ({ calls }));
	},
	fetchCalls: async () => {
		const { data: calls } = await axios.get(
			`${config.BASE_API_PATH}/calls`
		);

		set({ calls });
	},
	submitRating: async (data) => {
		await axios.post(`${config.BASE_API_PATH}/calls/rating`, data);

		get().fetchCalls();
	},
}));

export const useRequestStore = create((set, get) => ({
	requests: {
		caller: [],
		consultant: [],
	},
	setRequests: (requests) => set((state) => ({ requests })),
	fetchRequests: async () => {
		const { data: requests } = await axios.get(
			`${config.BASE_API_PATH}/requests`
		);

		set({ requests });
	},
	createRequest: async (consultantId, tagIds) => {
		await axios.post(`${config.BASE_API_PATH}/requests`, {
			consultantId,
			tagIds,
		});

		get().fetchRequests();
	},
	updateRequest: async (request) => {
		await axios.patch(
			`${config.BASE_API_PATH}/requests/${request.id}`,
			request
		);

		get().fetchRequests();
	},
	acceptRequest: async (id) => {
		await axios.patch(`${config.BASE_API_PATH}/requests/accept/${id}`);

		get().fetchRequests();
		useCallStore.getState().fetchCalls();
	},
	cancelRequest: async (id) => {
		await axios.patch(`${config.BASE_API_PATH}/requests/cancel/${id}`);

		get().fetchRequests();
	},
}));

export const useTagStore = create((set, get) => ({
	tags: [],
	selectedTags: [],
	setSelectedTags: (selectedTags) => set((state) => ({ selectedTags })),
	fetchTags: async () => {
		const { data: tags } = await axios.get(`${config.BASE_API_PATH}/tags`);

		set({ tags, selectedTags: get().selectedTags });
	},
}));

export const useUserStore = create((set, get) => ({
	userInfo: null,
	matchedConsultants: [],
	setUserInfo: (userInfo) => set((state) => ({ userInfo })),
	fetchUserInfo: async () => {
		const { data: userInfo } = await axios.get(
			`${config.BASE_API_PATH}/users/current`
		);

		set({ userInfo, matchedConsultants: get().matchedConsultants });
	},
	searchConsultants: async () => {
		const selectedTags = useTagStore.getState().selectedTags;

		const { data: matchedConsultants } = await axios.post(
			`${config.BASE_API_PATH}/users/search`,
			{ tagIds: selectedTags.map((t) => t.value) }
		);

		set({
			userInfo: get().userInfo,
			matchedConsultants,
		});
	},
	reportUser: async (data) => {
		await axios.post(`${config.BASE_API_PATH}/reports`, data);
	},
}));

export const useModalStore = create((set, get) => ({
	tokenModalOpen: false,
	prompt: null,
	setTokenModalOpen: (isTokenModalOpen) =>
		set(() => ({
			tokenModalOpen: isTokenModalOpen,
		})),
	openPrompt: (prompt) =>
		set(() => ({
			prompt,
		})),
	closePrompt: () =>
		set(() => ({
			prompt: null,
		})),
}));

export const usePaymentsStore = create((set, get) => ({
	paymentAccountInfo: null,
	fetchPaymentAccountInfo: async () => {
		const { data: paymentAccountInfo } = await axios.get(
			`${config.BASE_API_PATH}/payments/account/info`
		);

		set({ paymentAccountInfo });
	},
}));

// Export as a separate const for easier use in components.
export const openPrompt = useModalStore.getState().openPrompt;
