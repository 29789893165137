import { useState } from "react";
import { required, domainTypes } from "validatoolkit";
import TextField from "../../components/forms/fields/TextField";
import PasswordField from "../../components/forms/fields/PasswordField";
import MainCTA from "../../components/forms/fields/MainCTA.js";
import ValidatedForm from "../../utils/validation.js";

const LoginForm = ({ onSubmit = () => null }) => {
	const form = new ValidatedForm();

	form.useState(
		...useState({
			email: {
				value: "",
				validators: [required, ...domainTypes.email],
			},
			password: {
				value: "",
				validators: [required, ...domainTypes.password],
			},
		})
	);

	return (
		<form
			className="auth-form flex-column"
			onSubmit={(e) => e.preventDefault()}>
			<div className="flex-vertical-center flex-row m-b-s">
				<img
					src="/images/icon-48.png"
					alt=""
				/>
				<h2 className="m-l-xs">Log in</h2>
			</div>

			<div className="m-b-xs">
				<TextField
					label="E-mail"
					placeholder="e.g. name@domain.com"
					value={form.state.email.value}
					errorMessage={form.state.email.errorMessage}
					onChange={form.set("email")}
				/>
			</div>

			<div className="m-b-xs">
				<PasswordField
					label="Password"
					placeholder="e.g. *******"
					value={form.state.password.value}
					errorMessage={form.state.password.errorMessage}
					onChange={form.set("password")}
				/>
			</div>

			<span className="disclaimer">
				If you don't have an account, create one{" "}
				<a href="/register">here</a>. If you forgot your password,
				retrieve it <a href="/forgot-password">here</a>
			</span>

			<MainCTA
				className="primary m-t-s"
				onClick={() =>
					onSubmit({
						email: form.state.email.value,
						password: form.state.password.value,
					})
				}
				disabled={!form.state.isValid}>
				Login
			</MainCTA>
		</form>
	);
};

export default LoginForm;
