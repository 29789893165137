const TextField = ({
	label = "",
	placeholder = "",
	value = "",
	errorMessage = "",
	maxLength = undefined,
	mandatory = false,
	readOnly = false,
	onChange = () => null,
}) => {
	const errorShown = errorMessage && errorMessage.length;

	// TODO: Check how defaultValue swapped with value works.
	return (
		<div
			className={`labeled-field-container ${
				errorShown ? "error-shown" : ""
			}`}>
			<input
				className={`text-field ${errorShown ? "error" : ""}`}
				type="text"
				autoComplete="off"
				readOnly={readOnly}
				placeholder={placeholder}
				value={value}
				onChange={(event) => onChange(event.target.value)}
				maxLength={maxLength}
				required
			/>

			{label && label.length && (
				<label className="field-label">
					{label}
					{mandatory && <span className="mandatory"> *</span>}
				</label>
			)}

			{errorShown && (
				<span className="error-message">
					<img
						className="m-r-xxs"
						src="/images/icons/error-exclamation-mark.svg"
						alt=""
					/>
					{errorMessage}
				</span>
			)}
		</div>
	);
};

export default TextField;
