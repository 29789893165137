const CheckboxField = ({
	label = "",
	value = false,
	onChange = () => null,
}) => {
	return (
		<div className="checkbox-container">
			<label className="toggle">
				<input
					type="checkbox"
					className="toggle-checkbox"
					checked={value}
					onChange={(event) => onChange(event.target.checked)}
				/>
				<div className="toggle-switch"></div>
				<span className="toggle-label">{label}</span>
			</label>
		</div>
	);
};

export default CheckboxField;
