import { useState } from "react";
import { required, domainTypes } from "validatoolkit";
import TextField from "../../components/forms/fields/TextField";
import MainCTA from "../../components/forms/fields/MainCTA.js";
import ValidatedForm from "../../utils/validation.js";

const ForgotPasswordForm = ({ onSubmit = () => null }) => {
	const form = new ValidatedForm();

	form.useState(
		...useState({
			email: {
				value: "",
				validators: [required, ...domainTypes.email],
			},
		})
	);

	return (
		<form className="auth-form flex-column">
			<h2 className="m-b-s">Reset password</h2>

			<div className="m-b-xs">
				<TextField
					label="E-mail"
					placeholder="e.g. yourvalidemail@domain.com"
					value={form.state.email.value}
					errorMessage={form.state.email.errorMessage}
					onChange={form.set("email")}
				/>
			</div>

			<span className="disclaimer">
				Reset link will be sent to your e-mail address.
			</span>

			<MainCTA
				className="primary m-t-s"
				onClick={() => onSubmit({ email: form.state.email.value })}
				disabled={!form.state.isValid}>
				Request reset
			</MainCTA>
		</form>
	);
};

export default ForgotPasswordForm;
