import { Slider } from "react-burgers";
import { slide as Menu } from "react-burger-menu";
import { Outlet } from "react-router";
import { useState, useEffect } from "react";
import MainCTA from "../components/forms/fields/MainCTA";
import socketRegistry from "../socket/socketRegistry.js";
import { useUserStore } from "../store/globalStore.js";
import { signOut } from "../store/globalStore.js";

export default function AppLayout() {
	const fetchUserInfo = useUserStore((state) => state.fetchUserInfo);
	const [isMenuOpen, setMenuOpen] = useState(false);

	const showSystemNotification = (message) => {
		if (Notification.permission === "granted") {
			navigator.serviceWorker.ready.then(() => {
				const notification = new Notification("Test notification", {
					body: message,
					icon: "../images/icon-128.png",
				});

				notification.onclick = function (event) {
					event.preventDefault();
					window.open(window.location.href, "_blank");
				};
			});
		}
	};

	useEffect(() => {
		socketRegistry.initSocket();

		socketRegistry.mapActions({
			NOTIFICATION: (message) => {
				console.log("NOTIFICATION: ", message.payload);
				showSystemNotification(message.payload);
			},
		});

		fetchUserInfo();
	}, []);

	return (
		<div id="outer-container">
			<Menu
				isOpen={isMenuOpen}
				onClose={() => setMenuOpen(false)}
				className="app-layout-menu"
				pageWrapId="page-wrap"
				outerContainerId="outer-container">
				<a href="/">
					<img
						className="app-menu-logo m-b-m"
						src="/images/logo-text-white.png"
						alt=""
					/>
				</a>

				<a
					className="menu-item"
					href="/">
					Home
				</a>
				<a
					className="menu-item"
					href="/profile">
					Profile
				</a>
				<MainCTA
					className="negative white m-t-m"
					onClick={signOut}>
					Sign out
				</MainCTA>
			</Menu>

			<header className="app-layout-header">
				<div className="burger-button-wrapper">
					<Slider
						width={26}
						lineHeight={2}
						lineSpacing={5}
						borderRadius={1}
						active={isMenuOpen}
						onClick={() => setMenuOpen(!isMenuOpen)}
					/>
				</div>
			</header>

			<main
				id="page-wrap"
				className="app-layout-main">
				<Outlet />
			</main>
		</div>
	);
}
