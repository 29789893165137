import { useEffect, useState } from "react";
import ValidatedForm from "../../utils/validation.js";
import { required } from "validatoolkit";
import TextField from "./fields/TextField.js";
import generator from "ips-qr-code";
import qrcode from "qrcode";

const IPSForm = ({ ips = null, readOnly = false }) => {
	const [ipsUrl, setIpsUrl] = useState(null);

	const form = new ValidatedForm();

	form.useState(
		...useState({
			p: {
				value: ips?.p,
				validators: [required],
			},
			s: {
				value: ips?.s,
				validators: [required],
			},
			n: {
				value: ips?.n,
				validators: [required],
			},
			sf: {
				value: ips?.sf,
				validators: [required],
			},
			i: {
				value: ips?.i,
				validators: [required],
			},
			ro: {
				value: ips?.ro,
				validators: [required],
			},
			r: {
				value: ips?.r,
				validators: [required],
			},
			k: {
				value: ips?.k,
				validators: [required],
			},
			v: {
				value: ips?.v,
				validators: [required],
			},
			c: {
				value: ips?.c,
				validators: [required],
			},
		})
	);

	const setFormField = (key) => (value) => {
		form.set(key)(value);

		generateIPS();
	};

	const generateIPS = async () => {
		try {
			const ipsString = await generator({
				p: form.state.p.value,
				s: form.state.s.value,
				n: form.state.n.value,
				sf: form.state.sf.value,
				i: `RSD${(+form.state.i.value)
					?.toFixed(2)
					.toString()
					.replace(".", ",")}`,
				ro: form.state.ro.value,
				r: form.state.r.value,
				k: form.state.k.value,
				v: form.state.v.value,
				c: form.state.c.value,
			});

			const dataUrl = await qrcode.toDataURL(ipsString);

			setIpsUrl(dataUrl);
		} catch (error) {
			// console.log(error); // TODO: Display error.
		}
	};

	useEffect(() => {
		form.update(ips);

		generateIPS();
	}, [ips]);

	return (
		<div>
			{ipsUrl ? (
				<img
					src={ipsUrl}
					alt=""
				/>
			) : null}

			<div>
				<div className="col-6 m-b-xs">
					<TextField
						// label="Sifra placanja:"
						label="Payment code:"
						placeholder="e.g. 221"
						mandatory
						value={form.state.sf.value}
						errorMessage={form.state.sf.errorMessage}
						onChange={setFormField("sf")}
						readOnly={readOnly}
					/>
				</div>
				<div className="col-6 m-b-xs">
					<TextField
						// label="Iznos (u RSD):"
						label="Amount (in RSD):"
						placeholder="e.g. 1000"
						mandatory
						value={form.state.i.value}
						errorMessage={form.state.i.errorMessage}
						onChange={setFormField("i")}
						readOnly={readOnly}
					/>
				</div>
			</div>

			<div className="col-12 m-b-xs">
				<TextField
					// label="Naziv platioca:"
					label="Sender name:"
					placeholder="e.g. Marko Markovic"
					mandatory
					value={form.state.p.value}
					errorMessage={form.state.p.errorMessage}
					onChange={setFormField("p")}
					readOnly={readOnly}
				/>
			</div>
			<div className="col-12 m-b-xs">
				<TextField
					// label="Svrha placanja:"
					label="Purpose of payment:"
					placeholder="e.g. Uplata po racunu"
					mandatory
					value={form.state.s.value}
					errorMessage={form.state.s.errorMessage}
					onChange={setFormField("s")}
					readOnly={readOnly}
				/>
			</div>
			<div className="col-12 m-b-xs">
				<TextField
					// label="Naziv primaoca:"
					label="Recipient name:"
					placeholder="e.g. EPS Snabdevanje 11000 Beograd"
					mandatory
					value={form.state.n.value}
					errorMessage={form.state.n.errorMessage}
					onChange={setFormField("n")}
					readOnly={readOnly}
				/>
			</div>
			<div className="col-12 m-b-xs">
				<TextField
					// label="Racun primaoca:"
					label="Recipient bank account:"
					placeholder="e.g. 845000000014284968"
					mandatory
					value={form.state.r.value}
					errorMessage={form.state.r.errorMessage}
					onChange={setFormField("r")}
					readOnly={readOnly}
				/>
			</div>
			<div className="col-12 m-b-xs">
				<TextField
					// label="Poziv na broj:"
					label="Transaction ID:"
					placeholder="e.g. 123456789012345611"
					mandatory
					value={form.state.ro.value}
					errorMessage={form.state.ro.errorMessage}
					onChange={setFormField("ro")}
					readOnly={readOnly}
				/>
			</div>

			<div>
				<div className="col-4 m-b-xs">
					<TextField
						// label="Kod:"
						label="Code:"
						placeholder="e.g. PR"
						mandatory
						value={form.state.k.value}
						errorMessage={form.state.k.errorMessage}
						onChange={setFormField("k")}
						readOnly={readOnly}
					/>
				</div>
				<div className="col-4 m-b-xs">
					<TextField
						// label="Verzija:"
						label="Version:"
						placeholder="e.g. 01"
						mandatory
						value={form.state.v.value}
						errorMessage={form.state.v.errorMessage}
						onChange={setFormField("v")}
						readOnly={readOnly}
					/>
				</div>
				<div className="col-4 m-b-xs">
					<TextField
						// label="Znakovni skup:"
						label="Character set:"
						placeholder="e.g. 1"
						mandatory
						value={form.state.c.value}
						errorMessage={form.state.c.errorMessage}
						onChange={setFormField("c")}
						readOnly={readOnly}
					/>
				</div>
			</div>
		</div>
	);
};

export default IPSForm;
