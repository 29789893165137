import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";

import "./scss/style.scss";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>
);

Notification.requestPermission();

navigator.serviceWorker.register("/serviceWorker.js");
