import { useState } from "react";
import {
	mapToMultiselectFormat,
	mapFromMultiselectFormat,
} from "../../utils/helpers/uiHelper.js";
import MultiselectField from "../../components/forms/fields/MultiselectField.js";
import MainCTA from "../../components/forms/fields/MainCTA.js";
import ValidatedForm from "../../utils/validation.js";
import { isArray } from "validatoolkit";
import { openPrompt } from "../../store/globalStore.js";

const ProfileManageTagsForm = ({ user, tags, onSubmit = () => null }) => {
	const form = new ValidatedForm();

	form.useState(
		...useState({
			tags: {
				value: user.tags,
				validators: [isArray],
			},
		})
	);

	return (
		<form onSubmit={(e) => e.preventDefault()}>
			<h3 className="m-b-s">Manage tags</h3>

			<p className="m-b-s">
				You can manage your tags in the multiselect bellow:
			</p>

			<MultiselectField
				placeholder="Manage your tags..."
				value={mapToMultiselectFormat(form.state.tags.value)}
				options={mapToMultiselectFormat(tags)}
				onChange={form.set("tags")}
			/>

			<MainCTA
				className="m-t-s"
				disabled={!form.state.isValid}
				onClick={async () => {
					openPrompt({
						title: "Are you sure?",
						message:
							"Are you sure that you want to update your tags?",
						onConfirm: async () =>
							await onSubmit({
								tags: mapFromMultiselectFormat(
									form.state.tags.value
								),
							}),
					});
				}}>
				Update tags
			</MainCTA>
		</form>
	);
};

export default ProfileManageTagsForm;
