import { useState } from "react";
import IPSForm from "../../components/forms/IPSForm.js";
import NumberField from "../../components/forms/fields/NumberField.js";

const BuyTokensForm = ({ user, paymentAccountInfo }) => {
	const [numberOfTokens, setNumberOfTokens] = useState(1);

	return (
		<div>
			<p className="m-b-s">
				You can get more tokens buy paying the listed amount to the
				account bellow. Feel free to use the generated IPS payment code.
				You will be notified once your payment is processed.
			</p>

			<div className="ips-modal-form-container">
				<div className="m-b-xs">
					<NumberField
						label="Number of tokens"
						mandatory
						value={numberOfTokens}
						min={0}
						step={1}
						onChange={setNumberOfTokens}
					/>
				</div>

				{/* TODO: ro is not correct */}
				<IPSForm
					readOnly
					ips={{
						...paymentAccountInfo,
						p: `${user.name}`,
						i: numberOfTokens * 100,
						ro: `${new Date().getTime()}`,
					}}
				/>
			</div>
		</div>
	);
};

export default BuyTokensForm;
