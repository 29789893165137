import { useState } from "react";
import { required, domainTypes } from "validatoolkit";
import MainCTA from "../../components/forms/fields/MainCTA.js";
import ValidatedForm from "../../utils/validation.js";
import PasswordField from "../../components/forms/fields/PasswordField";

const SubmitNewPasswordForm = ({ onSubmit = () => null }) => {
	const form = new ValidatedForm();

	form.useState(
		...useState({
			password: {
				value: "",
				validators: [required, ...domainTypes.password],
				alsoValidate: ["repeatPassword"],
			},
			repeatPassword: {
				value: "",
				validators: [form.isEqual("password")],
			},
		})
	);

	return (
		<form className="auth-form flex-column">
			<h2 className="m-b-s">Reset password</h2>

			<div className="m-b-xs">
				<PasswordField
					label="Password"
					placeholder="e.g. *******"
					value={form.state.password.value}
					errorMessage={form.state.password.errorMessage}
					onChange={form.set("password")}
				/>
			</div>

			<div className="m-b-xs">
				<PasswordField
					label="Repeat password"
					placeholder="e.g. *******"
					value={form.state.repeatPassword.value}
					errorMessage={form.state.repeatPassword.errorMessage}
					onChange={form.set("repeatPassword")}
				/>
			</div>

			<MainCTA
				className="primary m-t-s"
				onClick={() =>
					onSubmit({
						password: form.state.password.value,
					})
				}
				disabled={!form.state.isValid}>
				Submit
			</MainCTA>
		</form>
	);
};

export default SubmitNewPasswordForm;
