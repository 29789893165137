import config from "../config.js";

class SocketRegistry {
	constructor() {
		this.actionMap = {};
	}

	initSocket = () => {
		const protocol =
			window.location.protocol === "https:" ? "wss://" : "ws://";

		this.socket = new WebSocket(
			protocol +
				config.API_HOST +
				`?token=${localStorage.getItem(config.LOCAL_STORAGE_TOKEN_KEY)}`
		);

		this.socket.onmessage = async (message) => {
			message = JSON.parse(message.data);

			if (this.actionMap[message.type])
				await this.actionMap[message.type](message);
		};
	};

	reconnect = () => {
		return this.initSocket();
	};

	mapActions = (actions) => {
		this.actionMap = { ...this.actionMap, ...actions };
	};
}

export default new SocketRegistry();
