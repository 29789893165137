import { google, outlook, office365, yahoo, ics } from "calendar-link";
import { useUserStore, useCallStore } from "../../store/globalStore.js";
import { getCallRole } from "../../utils/helpers/userHelper.js";
import RateCallForm from "../forms/RateCallForm.js";
import ReportUserForm from "../forms/ReportUserForm.js";
import { CallRoles } from "../../utils/constants.js";
import CallRating from "./CallRating.js";
import toast from "../../utils/toast.js";
import IPSForm from "../forms/IPSForm.js";

const Call = ({ call }) => {
	const userInfo = useUserStore((state) => state.userInfo);
	const submitRating = useCallStore((state) => state.submitRating);
	const reportUser = useUserStore((state) => state.reportUser);

	const event = {
		title: "My birthday party",
		description: "Be there!",
		start: "2019-12-29 18:00:00 +0100",
		duration: [3, "hour"],
	};

	const userCallRole = getCallRole(userInfo.id, call);

	const resolveRatingForm = () => {
		const shouldConsultantRatingFormBeRendered =
			userCallRole === CallRoles.Consultant &&
			(call.consultantRating === null ||
				call.consultantRating === undefined);

		const shouldCallerRatingFormBeRendered =
			userCallRole === CallRoles.Caller &&
			(call.callerRating === null || call.callerRating === undefined);

		if (shouldConsultantRatingFormBeRendered)
			return (
				<RateCallForm
					title={`Rate your call with ${call.caller.name}`}
					call={call}
					onSubmit={submitRating}
				/>
			);

		if (shouldCallerRatingFormBeRendered)
			return (
				<RateCallForm
					title={`Rate your call with ${call.consultant.name}`}
					call={call}
					onSubmit={submitRating}
				/>
			);
	};

	const submitReport = async (data) => {
		await reportUser(data);

		toast.success("User has successfully been reported! Check your inbox.");
	};

	return (
		<div className="subcontent-card">
			<div className="m-b-s">
				{call.tags && (
					<p>tags: {call.tags.map((tag) => tag.value).join(", ")}</p>
				)}
				{call.createdAt && <p>Call created at: {call.createdAt}</p>}
				{call.startDateTime && (
					<p>Start date time: {call.startDateTime}</p>
				)}
				{call.endDateTime && <p>End date time: {call.endDateTime}</p>}
				{call.callLink && (
					<a
						href={call.callLink}
						rel="noreferrer"
						target="_blank">
						{call.callLink}
					</a>
				)}

				{call.rating && (
					<p>
						callerRating: {call.rating.callerRating}{" "}
						consultantRating: {call.rating.consultantRating}
					</p>
				)}
				{call.caller && <p>caller: {call.caller.name}</p>}
				{call.consultant && <p>consultant: {call.consultant.name}</p>}
			</div>

			<div className="m-b-s">
				{call.consultantRating ? (
					<CallRating
						rating={call.consultantRating}
						comment={call.consultantComment}
					/>
				) : null}

				{call.callerRating ? (
					<CallRating
						rating={call.callerRating}
						comment={call.callerComment}
					/>
				) : null}

				{resolveRatingForm()}
			</div>

			<div className="m-b-s">
				<ReportUserForm
					reportedUser={
						userCallRole === CallRoles.Consultant
							? call.caller
							: call.consultant
					}
					onSubmit={submitReport}
				/>
			</div>

			<p>Call type indicator</p>

			<div className="m-b-s">
				<h4 className="m-b-s">Manage default IPS information</h4>

				<p className="m-b-m">
					IPS payments are only applicable for Serbia. If you are not
					from Serbia, arrange a different payment channel with the
					other person.
				</p>

				{call.consultant.ips ? (
					<IPSForm ips={call.consultant.ips} />
				) : null}
			</div>

			<a href={google(event)}>google,</a>
			<a href={outlook(event)}>outlook,</a>
			<a href={office365(event)}>office365,</a>
			<a href={yahoo(event)}>yahoo,</a>
			<a href={ics(event)}>ics</a>
		</div>
	);
};

export default Call;
