import { useState } from "react";
import { Link } from "react-router-dom";
import MoonLoader from "react-spinners/MoonLoader";

const MainCTA = ({
	href = undefined,
	disabled = false,
	className = "",
	children = null,
	onClick = () => null,
}) => {
	const [isSpinning, setIsSpinning] = useState(false);

	return href && href.length ? (
		<Link
			className={`btn ${className}`}
			to={href}>
			{children}
		</Link>
	) : (
		<button
			onClick={async () => {
				setIsSpinning(true);
				try {
					await onClick();
				} catch (error) {
					throw error;
				} finally {
					setIsSpinning(false);
				}
			}}
			className={`btn ${className} ${isSpinning ? "spinning" : ""}`}
			disabled={disabled || isSpinning}>
			{isSpinning ? (
				<MoonLoader
					color={"#fff"}
					size={18}
					className="spinner m-r-xs"
					cssOverride={{
						display: "inline-flex",
						position: "absolute",
					}}
				/>
			) : (
				""
			)}
			{children}
		</button>
	);
};

export default MainCTA;
