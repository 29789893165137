import { useNavigate } from "react-router-dom";
import { useAccountStore } from "../../store/globalStore.js";
import LoginForm from "../../components/forms/LoginForm.js";

const Login = () => {
	const login = useAccountStore((state) => state.login);

	const navigate = useNavigate();

	const submit = async (credentials) => {
		await login(credentials);

		navigate(`/`);
	};

	return (
		<div className="auth-page flex-center">
			<LoginForm onSubmit={submit} />
		</div>
	);
};

export default Login;
