const isDevelopment = window.location.hostname === "localhost";

const config = {
	API_HOST: isDevelopment ? "localhost:6175" : "api.consultproxy.com",
	BASE_API_PATH: isDevelopment
		? "http://localhost:6175"
		: "https://api.consultproxy.com",
	AUTHORIZATION_HEADER_NAME: "Authorization",
	BEARER_TOKEN_IDENTIFIER: "Bearer",
	LOCAL_STORAGE_TOKEN_KEY: "token",
	PAGE_SIZE: 3,
	MAX_IMAGE_SIZE: 2097152,
	STRIPE_KEY: "",
	COLORS: {
		BLUE: "#3c66b0",
		LIGHT_BLUE: "#63b4e4",
		DARK_BLUE: "#282560",
		RED: "#d91f2b",
		GREEN: "#00A699",
		YELLOW: "#eabb53",
		ORANGE: "#ff4d01",
		BLACK: "#000",
		GRAY: "#353434",
		WHITE: "#fff",
		LIGHT_GRAY: "#f3f3f3",
		DARK_GRAY: "#252525",
	},
};

export default config;
